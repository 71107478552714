    .react-multiple-carousel__arrow--left {
        left: 0;
    }

    .react-multiple-carousel__arrow--right {
        right: 0;
    }


.skill-slider{
    width: 100%;
    margin-top: 30px;
}

.react-multi-carousel-track {
    padding-left: 8%;  
}