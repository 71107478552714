.game-area {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(to bottom, #a1c4fd, #c2e9fb);
}

.score-display {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 24px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.bomb-fuse {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 12px;
  background-color: #8B4513;
  border-radius: 2px;
}

.bomb-spark {
  position: absolute;
  top: -8px;
  left: -3px;
  width: 10px;
  height: 10px;
  background: radial-gradient(circle, #FFD700, #FF4500);
  border-radius: 50%;
  animation: flicker 0.3s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes flicker {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}
  