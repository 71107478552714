/* Move your existing Footer CSS here and add any new styles */
.footer {
    padding: 50px 0;
    background-color: #121212;
  }
  
  .newsletter-bx {
    background: #FFFFFF;
    border-radius: 55px;
    padding: 85px 125px;
    margin-bottom: 80px;
    margin-top: -122px;
  }
  
  .new-email-bx {
    background: #fff;
    padding: 5px;
    border-radius: 20px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
  }
  
  .status-message {
    margin-top: 10px;
    text-align: center;
    &.success {
      color: green;
    }
    &.error {
      color: red;
    }
  }
  
  /* Add your other existing styles */